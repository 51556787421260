import React from 'react';

import styled, { css } from 'styled-components';

import {
  Button as NewButton,
  ButtonTypes as NewButtonTypes,
  ButtonSizes as NewButtonSizes,
} from '@dist-property-frontend/toolkit';

const BORDER_RADIUS = '4px';

export const CustomSelectSimple = styled.div`
  display: inline-flex;
  padding: 2px;
  overflow: hidden;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8cGF0aCBkPSJNMTYsMTAgTDEyLjE4ODQ0NDQsMTMuOTE5NTk4NiBDMTIuMDg0NjIyMiwxNC4wMjY3Mzk0IDExLjkxNTczMzMsMTQuMDI2NzM5NCAxMS44MTE5MTExLDEzLjkxOTk2NDMgQzExLjgxMTkxMTEsMTMuOTE5OTY0MyAxMS44MTE1NTU2LDEzLjkxOTU5ODYgMTEuODExNTU1NiwxMy45MTk1OTg2IEw4LDEwIiBpZD0iRFJPUERPV04tQlVUVE9OIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS13aWR0aD0iMS4yIj48L3BhdGg+ICAgIDwvZz48L3N2Zz4=)
    no-repeat right 4px center;
  width: 100%;

  /* IE11 Hack */
  select::-ms-expand {
    display: none;
  }
`;

export const CustomSelect = styled(CustomSelectSimple)`
  display: inline-flex;
  padding: 0;
  height: 40px;
  border-radius: 3px;
  background-color: #fefefe;
`;

export const FilterSelectCSS = css<{
  hasActiveBorder?: boolean;
  isFluid?: boolean;
}>`
  appearance: none;
  width: 100%;
  background-color: transparent;
  border: 1px solid #bbbbbb;
  height: 40px;
  padding-left: 20px;
  padding-right: 28px;
  font-size: 16px;
  line-height: 1.5;
  cursor: pointer;
  border-radius: ${BORDER_RADIUS};
  text-align: left;
  color: #333333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border: 1px solid #bbbbbb;
  }

  &.filterIsActive:not(:focus) {
    ${(props) =>
      props.hasActiveBorder &&
      `
    border: 2px solid black;
    `}
  }

  ${({ isFluid }) =>
    isFluid &&
    `
      width: 100%;
    `};

  @media only screen and (min-width: 704px) {
    font-size: 14px;
  }

  @media print {
    font-size: 12px;
  }
`;

export const Label = styled.label`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  display: block;
`;

export const FakeSelect = styled.button`
  ${FilterSelectCSS};
  width: 100%;
`;

export const BlockySelect = styled.div`
  display: inline-flex;
  overflow: hidden;

  width: 100%;
  height: 50px;

  @media only screen and (min-width: 704px) {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAyNCAyNCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5BcnRib2FyZDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iQXJ0Ym9hcmQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCI+ICAgICAgICA8cGF0aCBkPSJNMTYsMTAgTDEyLjE4ODQ0NDQsMTMuOTE5NTk4NiBDMTIuMDg0NjIyMiwxNC4wMjY3Mzk0IDExLjkxNTczMzMsMTQuMDI2NzM5NCAxMS44MTE5MTExLDEzLjkxOTk2NDMgQzExLjgxMTkxMTEsMTMuOTE5OTY0MyAxMS44MTE1NTU2LDEzLjkxOTU5ODYgMTEuODExNTU1NiwxMy45MTk1OTg2IEw4LDEwIiBpZD0iRFJPUERPV04tQlVUVE9OIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS13aWR0aD0iMS4yIj48L3BhdGg+ICAgIDwvZz48L3N2Zz4=)
      no-repeat right 4px center;
    height: 65px;
  }

  /* IE11 Hack */
  select::-ms-expand {
    display: none;
  }
`;

export const BlockySelectCSS = css`
  font-weight: 600;
  appearance: none;
  width: 100%;
  background-color: transparent;
  border: none;
  padding-left: 19px;
  padding-right: 10px;
  font-size: 12px;
  line-height: 1.5;
  cursor: pointer;
  border-radius: 4px;
  text-align: left;
  color: #333333;
  height: 50px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border: none;
  }

  &.filterIsActive:not(:focus) {
    border: 2px solid black;
  }

  .hasOpenMenu & {
    font-weight: 800;
    border-radius: 0;
    > button {
      border-radius: 0;
    }
  }

  ${(props: { isFluid?: boolean }) =>
    props.isFluid &&
    `
      width: 100%;
    `};

  @media only screen and (min-width: 704px) {
    font-size: 14px;
    height: 65px;
    padding-right: 28px;
  }

  @media print {
    font-size: 12px;
  }
`;

export const BlockyFakeSelect = styled.button`
  ${BlockySelectCSS}
`;

export const BlockyMenuCSS = css`
  display: none;
  position: absolute;
  padding: 16px 20px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-top: 0;
  top: 100%;
  z-index: 500;
  right: 0;
  left: -1px;
  min-width: 100vw;

  @media only screen and (min-width: 704px) {
    min-width: 384px;
    right: 0;
    left: initial;
  }

  @media only screen and (min-width: 1200px) {
    right: initial;
  }

  &.isOpen {
    display: block;
  }
`;

export const MenuCSS = css`
  display: none;
  position: absolute;
  padding: 12px 16px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
  top: calc(100% + 8px);
  z-index: 500;
  right: 0;

  &:before {
    z-index: 0;
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    top: -10px;
    left: 198px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;

    @media only screen and (min-width: 1200px) {
      left: 48px;
    }
  }

  &:after {
    z-index: -1;
    position: absolute;
    content: '';
    display: block;
    width: 0;
    height: 0;
    top: -11px;
    left: 197px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #dddddd;

    @media only screen and (min-width: 1200px) {
      left: 47px;
    }
  }

  @media only screen and (min-width: 1200px) {
    right: initial;
  }
`;

const CenteredButton = styled.div`
  max-width: 188px;
  margin: 20px auto 0;
`;

export const DoneButton = ({ onClick }: { onClick: (event: any) => void }) => (
  <CenteredButton>
    <NewButton
      data-testid="filter-done-button"
      buttonType={NewButtonTypes.TERTIARY}
      buttonSize={NewButtonSizes.SMALL}
      // buttonText="Done"
      fullWidth
      onClick={onClick}
    >
      Done
    </NewButton>
  </CenteredButton>
);
