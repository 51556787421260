import React, { useState, useRef, RefObject, useEffect } from 'react';

import Downshift, {
  ControllerStateAndHelpers,
  StateChangeOptions,
} from 'downshift';

import { callAll } from '../../../helpers/utils';

type MultiDownshiftProps<T> = {
  id: string;
  selectedItem: T[];
  onChangedState?: (
    changes: StateChangeOptions<T>,
    downshiftStateAndHelpers: ControllerStateAndHelpers<T>,
  ) => void;
  onChange: (selectedItem: T) => void;
  onRemoveItem: (item: T) => void;
  items: T[];
  itemToString: (item: T) => string;
  placeHolderText: string;
  children: any;
  onInputValueChange?: (input: string) => void;
  isFocused?: boolean;
  render?: any;
};

const MultiDownshift = <T extends { index?: number; displayName: string }>(
  props: MultiDownshiftProps<T>,
) => {
  const inputWrapperRef: RefObject<HTMLDivElement | null | undefined> =
    useRef();
  const inputRef: RefObject<any> = useRef();

  const [inputValue, setinputValue] = useState('');

  useEffect(() => {
    props.isFocused && inputRef.current?.focus();
  }, [props.isFocused]);

  const handleStateChange = (
    changes: StateChangeOptions<T>,
    downshiftStateAndHelpers: ControllerStateAndHelpers<T>,
  ) => {
    if (!downshiftStateAndHelpers.isOpen) {
      setinputValue('');
    }

    if (props.onChangedState) {
      props.onChangedState(changes, downshiftStateAndHelpers);
    }
  };

  const onInputChange = ({ target: { value } }: any) => {
    setinputValue(value);
  };

  const onRemoveTag = (item: T) => {
    props.onRemoveItem(item);
  };

  const onInputKeyDown = (event: any) => {
    const {
      target: { value },
      keyCode,
    } = event;
    const currentValue = value;
    switch (keyCode) {
      case 8:
        if (!currentValue) {
          event.preventDefault();
          popValue();
        }
        return;
      default:
        return;
    }
  };

  const popValue = () => {
    const { selectedItem, onRemoveItem } = props;
    if (onRemoveItem) {
      onRemoveItem({
        ...(selectedItem[selectedItem.length - 1] as Object),
        index: selectedItem.length - 1,
      } as T);
    }
  };

  const focusOnInput = () => {
    inputRef.current?.focus();

    if (typeof inputRef.current?.getInput === 'function') {
      inputRef.current.getInput().focus();
    }
  };

  const {
    id,
    render,
    itemToString,
    items,
    placeHolderText,
    selectedItem,
    children = render,
    ...rest
  } = props;

  return (
    <Downshift
      id={id}
      onStateChange={handleStateChange}
      //@ts-ignore FIX types error
      itemToString={itemToString}
      defaultHighlightedIndex={0}
      onInputValueChange={props.onInputValueChange}
      selectedItem={selectedItem as any}
      {...rest}
    >
      {(props: any) => {
        const { getInputProps, selectedItem } = props;

        const selectedItems = selectedItem.map((item: T, index: number) => {
          return { ...(item as Object), index };
        });

        const getAutoInputProps = (additionalProps?: any) =>
          getInputProps({
            ref: inputRef,
            value: inputValue,
            onChange: onInputChange,
            onKeyDown: onInputKeyDown,
            autoComplete: `off`,
            placeholder: selectedItem?.length ? '' : placeHolderText,
            disabled: selectedItem && selectedItem.length >= 50,
            ...additionalProps,
          });

        const getInputWrapperProps = ({
          onClick,
          ...additionalProps
        }: any = {}) => {
          const handleClick = callAll(focusOnInput, onClick);
          return {
            ref: inputWrapperRef,
            onClick: handleClick,
            'data-testid': 'input-value-wrapper',
            ...additionalProps,
          };
        };

        const getTagProps = (additionalProps?: any) => ({
          onRemove: callAll(focusOnInput, onRemoveTag),
          ...additionalProps,
        });

        const childProps = {
          ...props,
          selectedItems,
          getAutoInputProps,
          getInputWrapperProps,
          getTagProps,
          items,
          addItem: (index: any) => {
            setinputValue('');
            props.onChange(index);
          },
          clearInput: () => {
            setinputValue('');
          },
        };

        return children(childProps);
      }}
    </Downshift>
  );
};

export default MultiDownshift;
