import {
  Button,
  ButtonSizes,
  ButtonTypes,
  Icon,
  Illustration,
  Space,
  Text,
  TextFontWeight,
  TextSize,
} from '@dist-property-frontend/toolkit';
import { checkZeroResults } from '../helpers/filtersZeroResults';
import { FilterRequestAPI } from './FilterTypes';
import styled from 'styled-components';

export const FiltersZeroResults = ({
  filterAPI,
  onResetFilter,
}: {
  filterAPI: FilterRequestAPI[];
  onResetFilter: (filter: FilterRequestAPI) => void;
}) => {
  //get the active filters and display a button with CTA to reset the filter from search
  const activeFilters = checkZeroResults(filterAPI).filter(
    (filter) => filter !== null,
  );

  const Container = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  `;

  const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.toolkit.spacing.M16};
  `;

  return (
    <Container>
      <Illustration height={149} illustration="HOUSE" />
      <Space space="M32" />
      <Text as="p" size={TextSize.L20} fontWeight={TextFontWeight.SEMIBOLD}>
        We didn't find any properties
      </Text>
      <Space space={'S8'} />
      <Text as="p" size={TextSize.L16} fontWeight={TextFontWeight.SEMIBOLD}>
        Try removing some filters or searching in more areas
      </Text>
      <Space space={'M24'} />
      <ButtonsContainer>
        {activeFilters?.map((filter) => (
          <Button
            key={filter.displayName}
            buttonSize={ButtonSizes.X_SMALL}
            buttonType={ButtonTypes.SECONDARY}
            onClick={() => onResetFilter(filter)}
            Icon={<Icon icon="CLOSE" size={16} />}
            data-testid={`zero-results-btn-${filter.displayName}`}
          >
            {filter.displayName}
          </Button>
        ))}
      </ButtonsContainer>
    </Container>
  );
};
