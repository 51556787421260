import { useEffect, useState } from 'react';

// useful when dealing with url query parameters, as the next API
// will return a single value when there is only one value or an array
// if more than one. We usually force everything to be an array so we
// know what we're dealing with
import { PropertyCounts } from '../Filters';
import { SearchRequest, SECTIONS } from '../Filters/FilterTypes';

export const forceIntoArray = (value: any): any[] =>
  Array.isArray(value) ? value : [value];

// used to safely get a deeply nested value
// eg. getting   object.property.aNestedArray[0].oneMoreThing
// can be a pain in terms of defensiveness
// so we can use: safeGet(object, ['property', 'aNestedArray', 0, 'oneMoreThing'])
// to get the nested value without an error occuring if one of the properties doesn't exist.
// we can also provide a fallback value if we fail to get our deeply nested value.
// future JS syntax proposes: object.property?.thing?.anotherThing type syntax, which could be
// introduced right now using babel if it were agreed.
export const safeGet = (
  object: Object | any[] | undefined,
  pathArray: any[],
  fallback: any = undefined,
) => {
  if (object === 'undefined') {
    return fallback;
  }
  const target = pathArray.reduce(
    (obj, property) => obj && obj[property],
    object,
  );
  return typeof target !== 'undefined' ? target : fallback;
};

// This is used for HOC components which return prop getter functions,
// the callAll allows us to pass, for example, onClick handlers through the prop getter function
// while making sure we don't overwrite our internal onClick handler.
// That is, we can make sure our HOC handles it's internal state correctly, whilst also allowing
// the user of the HOC to add their own handlers wherever necessary
// a basic example: https://kentcdodds.com/blog/mixing-component-patterns
export const callAll =
  (...fns: Function[]) =>
  (...args: any) =>
    fns.forEach((fn: any) => {
      if (fn) {
        fn(...args);
      }
    });

export const debounce = (
  func: Function,
  wait: number,
  immediate = false,
): ((...args: any) => void) => {
  type Timer = ReturnType<typeof setTimeout>;
  let timeout: Timer | undefined;
  return function (this: any) {
    const args = arguments;
    const later = () => {
      timeout = undefined;
      if (!immediate) func.apply(this, args);
    };
    const callNow = immediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(this, args);
  };
};

// getRootNode polyfill. Uses node.getRootNode if possible, otherwise
// recursively calls itself with the node.parentNode until we find the rootNode.
export const getRootNode = (node: Node): any => {
  if (node.getRootNode) {
    return node.getRootNode();
  }
  if (node.parentNode !== null) {
    return getRootNode(node.parentNode);
  }

  return node;
};

type TextHighlightChildren = {
  preText: string;
  highlightedText?: string;
  postText?: string;
};
/**
 * The text highlighter is a HOC which is used to highlight a
 * substring within a given string. It works by providing the child component
 * with three sets of text: preText, highlightedText, postText.
 * This allows the child component to render the text how it needs to when it stiches these three
 * strings together in HTM:. Usually meaning that
 * preText and postText would have the same style and the highlightedText would be a notably
 * different style.
 *
 * eg.
 *
 * text: 'Harolds Cross',
 * searchString: 'old'
 * =>
 * preText: 'Har',
 * highlightedText: 'old',
 * postText: 's Cross'
 * */
export const TextHighlighter = (props: {
  text: string;
  searchString: string;
  children: (props: TextHighlightChildren) => JSX.Element;
}) => {
  const { text, searchString } = props;
  let childProps: TextHighlightChildren = {
    preText: text,
  };

  if (text && searchString) {
    const start = text.toLowerCase().indexOf(searchString.toLowerCase());
    const end = start + searchString.length;
    if (start > -1) {
      childProps = {
        preText: text.slice(0, start),
        highlightedText: text.slice(start, end),
        postText: text.slice(end, text.length),
      };
    }
  }
  return props.children(childProps);
};

export const getPropertyCountForSection = (
  section: SECTIONS,
  propertyCounts: PropertyCounts,
): string => {
  let count = 0;
  switch (section) {
    case SECTIONS.BUY:
      count = propertyCounts.residentialForSale;
      break;
    case SECTIONS.RENT:
      count = propertyCounts.residentialForRent;
      break;
    case SECTIONS.SHARE:
      count = propertyCounts.sharing;
      break;
    case SECTIONS.COMMERCIAL_BUY:
      count = propertyCounts.commercialForSale;
      break;
    case SECTIONS.COMMERCIAL_RENT:
      count = propertyCounts.commercialToRent;
      break;
    case SECTIONS.STUDENT_ACCOMMODATION_RENT:
      count = propertyCounts.studentAccommodationForRent;
      break;
    case SECTIONS.STUDENT_ACCOMMODATION_SHARE:
      count = propertyCounts.studentAccommodationToShare;
      break;
    case SECTIONS.PARKING_BUY:
      count = propertyCounts.parkingForSale;
      break;
    case SECTIONS.PARKING_RENT:
      count = propertyCounts.parkingToRent;
      break;
    case SECTIONS.NEW_HOMES:
      count = propertyCounts.newHomes;
      break;
    case SECTIONS.HOLIDAY_HOMES:
      count = propertyCounts.holidayHomes;
      break;
  }
  return count.toLocaleString();
};

const isPublishedAdStateOnlyFilterApplied = (
  searchRequest: SearchRequest,
): boolean => {
  const filters = searchRequest.andFilters.concat(searchRequest.filters);
  if (filters.length === 0) {
    return true;
  }

  if (filters.length === 1) {
    const filter = filters[0] as any;
    if (filter.name === 'adState') {
      return filter.values.length === 1 && filter.values[0] === 'published';
    }
  }
  return false;
};

/**
 * Indicates if we should show the area counts in the autocomplete area filter.
 * We do not want the ad counts to display when:
 *  - Any filters are being applied to the search (with the exception of the adState = published as this
 * filter will soon be applied by default to searches).
 *  - If the geoSearchType (is null or STORED_SHAPES) e.g a predefined polygon search.
 *  (e.g like a map search etc)
 * @param searchRequest The current search request that has been performed on this page
 */
export const showAreaCounts = (searchRequest: SearchRequest): boolean => {
  const isPredefinedPolygonBasedSearch =
    !searchRequest.geoFilter.geoSearchType ||
    searchRequest.geoFilter.geoSearchType === 'STORED_SHAPES';
  return (
    !(
      searchRequest.section &&
      searchRequest.section.indexOf('valuation-tool') === 0
    ) &&
    searchRequest.ranges != null &&
    searchRequest.ranges.length === 0 &&
    searchRequest.terms != null &&
    searchRequest.terms.length === 0 &&
    isPredefinedPolygonBasedSearch &&
    isPublishedAdStateOnlyFilterApplied(searchRequest)
  );
};

// Check if the current device is a mobile device
export const useCheckIsMobile = (breakpoint = 768) => {
  const [isMobileState, setIsMobileState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const handleResize = () => {
      setIsMobileState(window.innerWidth <= breakpoint);
    };

    // Check the initial window size on component mount
    handleResize();
    setIsLoading(false);

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return { isMobileState, isLoading };
};
